<template>
  <div>
    <div class="p-field">
      <label for="username2">Usuario</label>
      <InputText
        type="text"
        v-model="user.username"
        placeholder="Usuario"
        disabled
      />
    </div>
    <div class="p-field">
      <label for="username2">Contraseña</label>
      <InputText
        type="password"
        v-model="passwords.p1"
        placeholder="Escribe tu nueva contraseña"
      />
    </div>
    <div class="p-field">
      <label for="username2">Confirmar contraseña</label>

      <span class="a-input-icon-right">
        <InputText
          type="password"
          v-model="passwords.p2"
          placeholder="Confirma tu contraseña"
        />
        <img :src="require('@/assets/icons/check-blue.svg')" v-if="is_same" />
      </span>
    </div>
  </div>

  <div id="a-pass">
    <div :class="has_number ? 'a-success' : null">
      <span>Número</span>
      <hr />
    </div>
    <div :class="has_length ? 'a-success' : null">
      <span>Más de 6 caracteres</span>
      <hr />
    </div>
    <div :class="has_lowercase ? 'a-success' : null">
      <span>Minúsculas</span>
      <hr />
    </div>
    <div :class="has_uppercase ? 'a-success' : null">
      <span>Mayúsculas</span>
      <hr />
    </div>
    <div :class="has_special ? 'a-success' : null">
      <span>Caracteres especiales</span>
      <hr />
    </div>
  </div>

  <div>
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      v-if="canExit"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="changePassword"
    >
      Aceptar
    </button>
  </div>
</template>

<script>
export default {
  props: {
    canExit: Boolean,
  },
  data() {
    return {
      passwords: {
        p1: "",
        p2: "",
      },
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    has_number: {
      get: function () {
        return /\d/.test(this.passwords.p1);
      },
      set: function () {},
    },
    has_length: {
      get: function () {
        return this.passwords.p1.length > 8;
      },
      set: function () {},
    },
    has_lowercase: {
      get: function () {
        return /[a-z]/.test(this.passwords.p1);
      },
      set: function () {},
    },
    has_uppercase: {
      get: function () {
        return /[A-Z]/.test(this.passwords.p1);
      },
      set: function () {},
    },
    has_special: {
      get: function () {
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/; // eslint-disable-line
        return format.test(this.passwords.p1);
      },
      set: function () {},
    },
    is_same: {
      get: function () {
        return (
          this.passwords.p1 == this.passwords.p2 && this.passwords.p1 != ""
        );
      },
      set: function () {},
    },
    isValid() {
      if (
        this.has_length &&
        this.has_number &&
        this.has_lowercase &&
        this.has_uppercase &&
        this.has_special &&
        this.is_same
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    changePassword() {
      this.isLoading = true;
      if (this.isValid) {
        let formData = new FormData();
        formData.append("username", this.user.username);
        formData.append("password", this.passwords.p1);
        this.axios
          .put(`${this.http}userchagepassword/${this.user.pk}/`, formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
            },
          })
          .then(() => {
            this.$router.push({ name: "Projects" });
          })
          .catch((error) => {
            this.isLoading = true;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
#a-pass {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7em, 1fr));
}

#a-pass div {
  height: 80%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 10px 0;
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  color: var(--text-color);
}
#a-pass div hr {
  width: 100%;
  border: 1px solid var(--color-1);
}

.a-success span {
  color: var(--color-3);
}
.a-success hr {
  border: 1px solid var(--color-3) !important;
}

.a-success-text {
  color: var(--color-3);
}
.a-success-hr {
  border: 1px solid var(--color-3) !important;
}
</style>
