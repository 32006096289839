<template>
  <div class="a-container">
    <Menubar>
      <template #end>
        <button class="a-menubar-btn" @click="$router.push('/')">
          <img
            id="a-form-habitat"
            :src="require('@/assets/icons/arrow-left-white.svg')"
            style="margin: 0 10px; height: 12px"
          />
          Regresar
        </button>
      </template>
    </Menubar>
    <div id="a-login">
      <div id="a-form">
        <img
          id="a-form-habitat"
          :src="require('@/assets/images/logo/habitat-contigo.svg')"
          style="margin: 20px 0; height: 100px"
        />
        <div>
          <h4>Iniciar sesión</h4>
          <span class="a-input-icon">
            <img :src="require('@/assets/icons/user.svg')" />
            <InputText
              type="text"
              v-model="form.username"
              placeholder="Usuario"
            />
          </span>
          <span class="a-input-icon">
            <img :src="require('@/assets/icons/lock.svg')" />
            <InputText
              type="password"
              v-model="form.password"
              placeholder="Contraseña"
            />
          </span>
          <button
            :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
            :disabled="isLoading || !isValid"
            @click="login"
          >
            Iniciar sesión
          </button>
        </div>
      </div>
      <div id="a-login-img">
        <img :src="require('@/assets/images/auth/login.jpeg')" />
      </div>
    </div>

    <img
      :src="require('@/assets/images/logo/villavo-white.svg')"
      style="margin-top: 20px; height: 48px"
    />
  </div>

  <Dialog
    header="Cambiar contraseña"
    v-model:visible="isChangeVisible"
    v-if="isChangeVisible"
    :modal="true"
    :closable="false"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <ChangePassword :canExit="false" />
  </Dialog>
  <Toast />
</template>

<script>
import ChangePassword from "@/components/auth/ChangePassword.vue";
export default {
  name: "Login",
  components: {
    ChangePassword,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      isLoading: false,
      isChangeVisible: false,
    };
  },
  computed: {
    isValid() {
      return this.form.username !== "" && this.form.password !== "";
    },
  },
  methods: {
    login() {
      this.isLoading = true;

      this.axios
        .post(`${this.http}rest-auth/login/`, this.form)
        .then((response) => {
          sessionStorage.setItem("cfta", response.data.access_token);
          sessionStorage.setItem("cftr", response.data.refresh_token);
          this.$store.commit("setUser", response.data.user);

          if (!this.$store.state.user.changePassword) {
            this.isChangeVisible = true;
          } else {
            this.$router.push({ name: "Projects" });
          }
        })
        .catch((error) => {
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            errors.forEach((err) => {
              message += response[err];
            });
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: message,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 954px) {
  #a-login {
    flex-flow: column-reverse !important;
    max-width: 80% !important;
    width: 80% !important;
    height: 70% !important;
  }

  #a-login-img {
    max-width: 100% !important ;
    min-width: 100% !important;
    width: 100% !important;
    height: 200px !important;
  }
}

@media (max-width: 480px) {
  #a-form h4 {
    font-size: 16px !important;
    margin: 10px;
  }
  #a-form #a-form-habitat {
    height: 60px !important;
  }
}
</style>

<style scoped>
.a-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--color-1);
}

#a-login {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: none;
  border-radius: 20px;
  max-width: 70%;
  min-height: 50%;
  width: 80%;
  height: 60%;
}

#a-login-img {
  max-width: 50%;
  min-width: 50%;
  width: 50%;
  height: 100%;
  position: relative;
}

#a-login-img:after {
  border-radius: 20px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(0, 217, 134, 0.2) 50%,
    rgba(0, 217, 134, 0.7) 100%
  );
}

#a-login-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  height: inherit !important;
}

#a-form {
  margin: 0 40px;
  padding: 20px;
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
#a-form div {
  width: 100%;
  text-align: left;
}
#a-form h4 {
  color: #333333;
  font-family: "Quicksand", sans-serif !important;
  font-size: 24px;
}

.p-menubar {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>

<style>
.p-menubar-mobile-active,
.p-menubar-button {
  display: none !important;
}
</style>
